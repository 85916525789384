import React, { useRef } from "react";
import { 
  Flex, 
  Layout,  
  Image,
  Table,
  Timeline,
  Carousel,
  Button
} from "antd";
import './Home.css';
import { LeftOutlined, RightOutlined } from "@ant-design/icons";


const { Content } = Layout;
const { Item } = Timeline;

const contentStyle = {
  textAlign: 'center',
  minHeight: 120,
  lineHeight: '120px',
  color: '#fff',
  backgroundColor: '#0958d9',
};

const columns = [
  {
    title: '',
    dataIndex: 'name',
    dataLabel: 'name',
    key: 'name',
    align: 'center',
    width: '12.5%'
  },
  {
    title: '',
    dataIndex: 'con',
    dataLabel: 'con',
    key: 'con',
    align: 'center', 
    width: '12.5%'
  },
  {
    title: 'Conventionnal Retainer (Composite Pad)',
    dataIndex: 'res',
    dataLabel: 'res',
    key: 'res',
    align: 'center', 
    width: '25%'
  },
  {
    title: 'iRetain',
    dataIndex: 'ire',
    dataLabel: 'ire',
    key: 'ire',
    align: 'center',
    width: '25%'
  },
];

const dataSource = [
  {
    key: '1',
    name: (<span className="h1style">Design</span>),
    con: (<div></div>),
    res: (
      <div>
        <img src="/images/xcircle.png" alt="Check" style={{ marginBottom: 5 }} />
      </div>
    ),
    ire: (
      <div>
        <img src="/images/check.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Optimal shape and position thanks to CAD software and AI</span>
      </div>
    )
  },
  {
    key: '2',
    name: (<span className="h1style"></span>),
    con: (<span className="h2style">Pad's Thickness</span>),
    res: (
      <div>
        <img src="/images/xcircle.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Random: thickness could be less than 0,2mm above the wire ( therefore very fragile), or more than 1,5mm in other areas</span>
      </div>
    ),
    ire: (
      <div>
        <img src="/images/check.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Precisely designed;   0,45mm all over ( except at the wire level: 0,45+0,45=0,9mm)</span>
      </div>
    )
  },
  {
    key: '3',
    name: (<span className="h1style"></span>),
    con: (<span className="h2style">Pad’s surface, bonding surface</span>),
    res: (
      <div>
        <img src="/images/xcircle.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Random: impossible to precisely quantify the bonding surface and therefore predict the maximal tolerable force ( assuming that the force applied to the composite pad is uniformly distributed) and therefore impossible to guarantee that the pad will not be fractured under ordinary eating forces. 				
        </span>
      </div>
    ),
    ire: (
      <div>
        <img src="/images/check.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Surface precisely calculated, in mm2, with a 0,1mm preciseness , the maximal tolerable force can therefore be calculated. the structure can be sized according to the forces to be expected</span>
      </div>
    )
  },
  {
    key: '4',
    name: (<span className="h1style"></span>),
    con: (<span className="h2style">Pad’s Surface condition</span>),
    res: (
      <div>
        <img src="/images/xcircle.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Random, poor polishing</span>
      </div>
    ),
    ire: (
      <div>
        <img src="/images/check.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Mirror shine polish</span>
      </div>
    )
  },
  {
    key: '5',
    name: (<span className="h1style"></span>),
    con: (<span className="h2style">Wire shape and position</span>),
    res: (
      <div>
        <img src="/images/xcircle.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Often imprecise fit, wire often too close to the occlusal contacts or to the gum(can be well designed and positioned when prepared indirectly in a lab).</span>
      </div>
    ),
    ire: (
      <div>
        <img src="/images/check.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Optimal shape and position thanks to CAD software and AI</span>
      </div>
    )
  },
  {
    key: '6',
    name: (<span className="h1style">Mechanical properties</span>),
    con: (<span className="h2style">Pad</span>),
    res: (
      <div>
        <img src="/images/xcircle.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Composite pads are fragile: high fracture risk</span>
      </div>
    ),
    ire: (
      <div>
        <img src="/images/check.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Unbreakable in a human mouth</span>
      </div>
    )
  },
  {
    key: '7',
    name: (<span className="h1style"></span>),
    con: (<span className="h2style"></span>),
    res: (
      <div>
        <img src="/images/xcircle.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Rigid wires do not dampen the occlusal forces, which increases the risk of cracking the composite pad. Flexible wire and common multistrand wire present the risk of non desired movement of the tooth while bonded  (wire syndrome) (Superlestic nickel titanium wire have the optimal material properties for a retainer wire: to move back to the original shape when deformed, with no risk of plastic deformation).</span>
      </div>
    ),
    ire: (
      <div>
        <img src="/images/check.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Superlestic nickel titanium wire have the optimal material properties for a retainer wire: to move back to the original shape when deformed, with no risk of plastic deformation.</span>
      </div>
    )
  },
  {
    key: '8',
    name: (<span className="h1style">Durabily</span>),
    con: (<span className="h2style"></span>),
    res: (
      <div>
        <img src="/images/xcircle.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">The composite is prone to fatigue, particularly the adhesive layer at the tooth interface.                                                                             fatigue could either lead to an adhesive failure at the tooth interface, or to a composite fracture under a low level force. </span>
      </div>
    ),
    ire: (
      <div>
        <img src="/images/check.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">The high tenacity pads are unbreakable and protect both the wire and the bonding interface like a shield.</span>
      </div>
    )
  },
  {
    key: '9',
    name: (<span className="h1style"></span>),
    con: (<span className="h2style">Installation duration</span>),
    res: (
      <div>
        <img src="/images/xcircle.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Time consuming. Even if the wire is prepared with indirect techniques, composite pads are to be made.</span>
      </div>
    ),
    ire: (
      <div>
        <img src="/images/check.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Easy to use transfert tray , less than 4 minutes for a perfect result.</span>
      </div>
    )
  },
  {
    key: '10',
    name: (<span className="h1style"></span>),
    con: (<span className="h2style">Patient Comfort </span>),
    res: (
      <div>
        <img src="/images/xcircle.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Gingival inflammation and cleaning difficulties are common                                                                                                                                                                                                                         -  Maintenance and emergency appointments are frequently necessary because of composite fracture or a tongue hurting wire.</span>
      </div>
    ),
    ire: (
      <div>
        <img src="/images/check.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Optimal design that makes cleaning easier.</span>
      </div>
    )
  }
];

const steps = [
  {
    id: 1,
    image: "/images/stepp1.png", 
    icon: "/images/step1.png",
    description: (
      <Flex align="flex-start" gap={16}>
        <div style={{ lineHeight: 0 }}>
          <Image
            src="/images/icon1.png"
            alt="Icon"
            preview={false}
            width={window.innerWidth < 768 ? 36 : 77}
          />
        </div>

        <div style={{ textAlign: "right", flex: 1 }}>
          <div className="ststyle" style={{ marginBottom: 8 }}>Prescription</div>

          <div className="s1style" style={{ position: "relative", paddingLeft: 20 }}>
           • Create a file for your patient on iretain.com.
          </div>

          <div className="s1style" style={{ position: "relative", paddingLeft: 20 }}>
           • Transfer its digital impression either directly from your scanner or drag and drop it on its file.
          </div>
        </div>
      </Flex>
    )
  },
  {
    id: 2,
    image: "/images/stepp2.png", 
    icon: "/images/step2.png",
    description: (
      <Flex align="flex-start" gap={16} style={{height: 250}}>
        <div style={{ lineHeight: 0 }}>
          <Image
            src="/images/icon2.png"
            alt="Icon"
            preview={false}
            width={window.innerWidth < 768 ? 36 : 77}
          />
        </div>

        <div style={{ textAlign: "right", flex: 1 }}>
          <div className="ststyle" style={{ marginBottom: 8 }}>Design</div>

          <div className="s1style" style={{ position: "relative", paddingLeft: 20 }}>
           • Geometric algorithms semi-automatically design personalized and optimized wire and pads, with a perfect fit and optimal comfort.
          </div>

          <div className="s1style" style={{ position: "relative", paddingLeft: 20 }}>
           • The orthodontist can check and, if wanted, adjust the position and shape of the pads and wire.
          </div>
        </div>
      </Flex>
    )
  },
  {
    id: 3,
    image: "/images/stepp3.png", 
    icon: "/images/step3.png",
    description: (
      <Flex align="flex-start" gap={16} style={{height: 250}}>
        <div style={{ lineHeight: 0 }}>
          <Image
            src="/images/icon3.png"
            alt="Icon"
            preview={false}
            width={window.innerWidth < 768 ? 36 : 77}
          />
        </div>
        <div style={{ textAlign: "right", flex: 1 }}>
          <div className="ststyle" style={{ marginBottom: 8 }}>Production</div>

          <div className="s1style" style={{ position: "relative", paddingLeft: 20 }}>
           • Additive manufacturing of metal pads in Ni-Cr alloy for optimum strength.
          </div>

          <div className="s1style" style={{ position: "relative", paddingLeft: 20 }}>
           • Personalized nickel-titanium wire assembled with the pads in a transfer tray.
          </div>
        </div>
      </Flex>
    )
  },
  {
    id: 4,
    image: "/images/stepp4.png", 
    icon: "/images/step4.png",
    d1: "/images/icon4.png",
    description: (
      <Flex align="flex-start" gap={16} style={{height: 250}}>
        <div style={{ lineHeight: 0 }}>
          <Image
            src="/images/icon4.png"
            alt="Icon"
            preview={false}
            width={window.innerWidth < 768 ? 36 : 77}
          />
        </div>
        <div style={{ textAlign: "right", flex: 1 }}>
          <div className="ststyle" style={{ marginBottom: 8 }}>Delivery</div>

          <div className="s1style" style={{ position: "relative", paddingLeft: 20 }}>
           • Tracking number avaible on the website.
          </div>

          <div className="s1style" style={{ position: "relative", paddingLeft: 20 }}>
           • We try our best to deliver it in less than 2 weeks once the design is approved.
          </div>
        </div>
      </Flex>
    )
  },
  {
    id: 5,
    image: "/images/stepp5.png", 
    icon: "/images/step5.png",
    d1: "/images/icon5.png",
    description: (
      <Flex align="flex-start" gap={16} style={{height: 250}}>
        <div style={{ lineHeight: 0 }}>
          <Image
            src="/images/icon5.png"
            alt="Icon"
            preview={false}
            width={window.innerWidth < 768 ? 36 : 77}
          />
        </div>
        <div style={{ textAlign: "right", flex: 1 }}>
          <div className="ststyle" style={{ marginBottom: 8 }}>Bonding</div>

          <div className="s1style" style={{ position: "relative", paddingLeft: 20 }}>
           • Easy to use transfer tray, to ensure quick and precise positioning.
          </div>

          <div className="s1style" style={{ position: "relative", paddingLeft: 20 }}>
           • Almost the same quick bonding protocol as for indirect bonding of lingual brackets.
          </div>
          
        </div>
      </Flex>
    )
  },
];

const images = [
  "/images/c01.png",
  "/images/c02.png"
];

const groups = [
  "/images/Group01.png",
  "/images/Group02.png",
  "/images/Group03.png",
  "/images/Group04.png",
  "/images/Group05.png",
  "/images/Group06.png",
  "/images/Group07.png"
];

const Home = () => {

    const carouselRef = useRef(null);
  
    const handlePrev = () => {
      carouselRef.current.prev();
    };
  
    const handleNext = () => {
      carouselRef.current.next();
    };

  return (
      <Content style={contentStyle}>
        <Flex className="responsive-flex" vertical
          style={{
            background: ' #FFFFFF',
          }}
        >

          <Flex className="responsive-flex" style={{ width: '100%',padding: 80, gap:30 }}>
            <Flex style={{ flex: 1 }}>
              <Flex vertical style={{ alignItems: 'flex-start' }}>
                <Image
                  src="/images/logoiRetain1.png"
                  alt="Logo"
                  preview={false}
                  width={250}
                  style={{ display: 'block' }}
                />
                <div className="responsive-text"
                  style={{
                    fontFamily: 'Inter-Semi Bold, Inter, sans-serif',
                    fontWeight: 600,
                    fontSize: 60,
                    color: '#033190',
                    lineHeight: '72px', 
                    textAlign: 'left',
                  }}
                >
                  The optimal orthodontic retainer
                </div>

                <Flex align="center" style={{ marginTop: 26 }}>
                  <span
                    style={{
                      fontFamily: 'Inter, sans-serif',
                      fontWeight: 500,
                      fontSize: 18,
                      color: '#060042',
                      marginLeft: 18,
                      lineHeight: '27px',
                      textAlign: 'left',
                    }}
                  >
                    The world's first retainer featuring:<br></br>
                    - a perfect fit, fully personalized, 0.4mm<span style={{ fontSize: '24px', verticalAlign: '-1px' }}>⌀</span> super-elastic Nickel-Titanium wire<br></br>
                    - unbreakable, ultra thin personalized pads
                  </span>
                </Flex>

                <Flex direction="column" align="center" style={{ marginTop: 5 }}>
                  <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', textAlign: 'left', marginLeft: 18 }}>
                    <span
                      style={{
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 500,
                        fontSize: 18,
                        color: '#060042',
                        lineHeight: '27px', 
                      }}
                    >
                      Optimized and engineered with
                    </span>
                    <span
                      style={{
                        fontFamily: 'Inter-Bold Italic, Inter, sans-serif',
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                        fontSize: 18,
                        color: '#64A7E6',
                        lineHeight: '27px', 
                      }}
                    >
                      &nbsp;advanced CAD
                    </span>
                    <span
                      style={{
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 500,
                        fontSize: 18,
                        color: '#060042',
                        lineHeight: '27px', 
                      }}
                    >
                      &nbsp;and
                    </span>
                    <span
                      style={{
                        fontFamily: 'Inter-Bold Italic, Inter, sans-serif',
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                        fontSize: 18,
                        color: '#64A7E6',
                        lineHeight: '27px', 
                      }}
                    >
                      &nbsp;AI Technology
                    </span>
                    <span
                      style={{
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 500,
                        fontSize: 18,
                        color: '#060042',
                        lineHeight: '27px', 
                      }}
                    >
                      &nbsp;for an unprecedented fit, comfort and durability.
                    </span>
                  </div>
                </Flex>

                <Flex 
                    vertical 
                    align="center" 
                    style={{ marginTop: 30 }}
                  >
                    <span
                      style={{
                        fontFamily: 'Default Sans',
                        fontWeight: 600,
                        fontSize: 36,
                        color: '#F802F6',
                        lineHeight: '43.2px',
                        textAlign: 'center', 
                        marginBottom: 16 
                      }}
                    >
                      iRetain your smile !
                    </span>
                    <Image
                      src="/images/underscore.png"
                      alt="Logo"
                      preview={false}
                      width={293}
                      style={{ 
                        display: 'block',
                        margin: '0 auto'
                      }}
                    />
                  </Flex>
              </Flex>
            </Flex>

            <Flex style={{ flex: 1, justifyContent: 'center', alignItems: 'center'}}>
              <video className="responsive-video" 
                controls
                autoPlay
                muted
                loop 
                controlsList="nodownload" 
                onContextMenu={(e) => e.preventDefault()}
                style={{ maxWidth: '100%', height: 'auto', borderRadius: 30, paddingLeft:10, paddingRight:10}} 
              >
                <source src="/videos/iretain.mp4" type="video/mp4" /> 
              </video>
            </Flex>
          </Flex>


          <Flex className="responsive-flex" vertical style={{background: ' #FAFAFA', padding:80}}>
            <div 
              style={{
                fontFamily: 'Inter, Inter;',
                fontWeight: 500,
                fontSize: 36,
                color:'#060042',
                lineHeight: '43.2px', 
                textAlign: 'center',
              }}
            >
              The solution to conventional retainers’ failures
            </div>

            <div style={{ position: "relative", width: "100%", maxWidth: 1080, paddingTop:40, margin: "0 auto" }}>
              <Button
                type="text"
                icon={<LeftOutlined />}
                onClick={handlePrev}
                style={{
                  position: "absolute",
                  left: -50,
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                }}
              />
              <Button
                type="text"
                icon={<RightOutlined />}
                onClick={handleNext}
                style={{
                  position: "absolute",
                  right: -50,
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                }}
              />

              <Carousel
                ref={carouselRef}
                dots={false}
                slidesToShow={window.innerWidth <= 768 ? 1 : 3}
                slidesToScroll={1}
                autoplay
                autoplaySpeed={2000}
                infinite
                responsive={[
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                    },
                  },
                ]}
              >
                {groups.map((image, index) => (
                  <div className="responsive-carousel"
                    key={index}
                    style={{
                      width: "calc(50% - 10px)",
                      marginRight: "20px", 
                    }}
                  >
                    <Image
                      src={image}
                      alt={`Image ${index + 1}`}
                      style={{ width: "100%", height: "auto", borderRadius: 8 }}
                    />
                  </div>
                ))}
              </Carousel>

            </div>


            <Flex className="responsive-flex" style={{
                gap: 30, 
                width: '100%',
                paddingTop:40
              }}>
              <div 
                style={{
                  fontFamily: 'Inter, Inter;',
                  fontWeight: 400,
                  fontSize: 18,
                  lineHeight: '30px', 
                  textAlign: 'left',
                  flex: 1,
                  color: 'rgba(0,0,0,0.7)'
                }}
              >
                The vast majority of conventionnal retainer failures are composite pad fractures (green arrow). The composite resin that makes up the pads is a brittle material, with numerous defects and microcracks. Above a certain threshold stress, the pad fractures, with a fracture line that passes through the defects and the retention wire, which constitutes stress concentration sites. 
              </div>

              <div 
                style={{
                  fontFamily: 'Inter, Inter;',
                  fontWeight: 400,
                  fontSize: 18,
                  lineHeight: '30px', 
                  textAlign: 'left',
                  flex: 1,
                  color: 'rgba(0,0,0,0.7)'
                }}
              >
                This threshold stress decreases with material fatigue. Patients then say: "My retainer fractured even though I was only eating salad or sliced ​​bread."  <br/>
                Wire fracture and complete pad debonding are rarer (purple arrow).
              </div>
            </Flex>

          </Flex>

        </Flex>

        <Flex
          style={{
            padding: '120px 0 0 0', 
            background: '#FFFFFF',
          }}
          vertical
        >
          <Flex
            justify="center"
            style={{
              fontSize: 36,
              fontWeight: 500,
              marginBottom: 60,
              color: '#060042',
              lineHeight: 0,
            }}
          >
            Advantages of iRetain
          </Flex>

          <Flex justify="center" style={{ width: '100%' }}>
            <div
              className="table-container"
              style={{
                maxWidth: 1220, 
                width: '100%',  
                margin: '0 auto', 
              }}
            >
              <Table
                id="table_a"
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                rowClassName={(record, index) => (index % 2 === 0 ? 'h-row-even' : 'h-row-odd')}
                components={{
                  header: {
                    cell: (props) => (
                      <th
                        {...props}
                        style={{
                          background: '#6CA8E6',
                          color: '#FFFFFF',
                          fontWeight: 'bold',
                        }}
                      />
                    ),
                  },
                }}
              />
              <div className="last-column-border" />
            </div>
          </Flex>
        </Flex>

        <Flex
          style={{
            padding: '120px 0 0 0', 
            background: '#FFFFFF',
          }}
          vertical
        >

            <Flex
              justify="center" 
              style={{
                fontSize: 36,
                fontWeight: 500,
                marginBottom: 20,
                lineHeight: 1.5,
                color: '#060042'
              }}
            >
              The Step-by-Step Process
            </Flex>

            <Flex justify="center" style={{ width: "100%", marginTop: 40 }}>
              <Flex style={{ width: "80%", maxWidth: 1220, gap: 24 }}>
                {/*<Flex vertical style={{ width: "45%", gap: 24, alignItems: "flex-end" }}>
                  {steps.map((step) => (
                    <div key={step.id} style={{ height: 220,  alignItems: "left" }}>
                      <Image
                        src={step.image}
                        alt={`Step ${step.id}`}
                        preview={false}
                        style={{ width: 380, height: 170 }}
                      />
                    </div>
                  ))}
                </Flex>*/}

                <Flex vertical style={{ width: "10%", justifyContent: "center", alignItems: "center" }}>
                  <Timeline mode="left">
                    {steps.map((step) => (
                      <Item
                        key={step.id}
                        dot={
                          <Image
                            src={step.icon}
                            alt={`Step ${step.id}`}
                            preview={false}
                            width={window.innerWidth < 768 ? 24 : 36}
                          />
                        }
                        style={{ height: window.innerWidth < 768 ? 365 : 245 }}
                      >
                      </Item>
                    ))}
                  </Timeline>
                </Flex>

                <Flex vertical style={{ width: "90%", gap: 24, justifyContent: "center", marginTop: '-55px' }}>
                  {steps.map((step) => (
                    <div key={step.id} style={{ height: window.innerWidth < 768 ? 340 : 220,  alignItems: "center"}}>
                      <Flex align="center" gap={8}>
       
                        <div>{step.description}</div>
                      </Flex>
                    </div>
                  ))}
                </Flex>
              </Flex>
            </Flex>

        </Flex>    


        <Flex
          style={{
            padding: '20px 80px 100px 80px',
            background: '#FFFFFF',
          }}
          vertical 
        >  
            <Flex
              justify="center" 
              style={{
                fontSize: 36,
                fontWeight: 500,
                color: '#060042'
              }}
            >
              Clinical cases
            </Flex>

            <Flex
              justify="center" 
              style={{
                fontSize: 18,
                fontWeight: 500,
                marginTop: -60,
                color: 'rgba(0,0,0,0.6)'
              }}
            >
              A glimpse of our portfolio
            </Flex>

            <div style={{ position: "relative", width: "100%", maxWidth: 850, margin: "0 auto" }}>
              <Button
                type="text"
                icon={<LeftOutlined />}
                onClick={handlePrev}
                style={{
                  position: "absolute",
                  left: -50,
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                }}
              />
              <Button
                type="text"
                icon={<RightOutlined />}
                onClick={handleNext}
                style={{
                  position: "absolute",
                  right: -50,
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                }}
              />

              <Carousel
                ref={carouselRef}
                dots={false}
                slidesToShow={window.innerWidth <= 768 ? 1 : 2}
                slidesToScroll={1}
                autoplay
                autoplaySpeed={2000}
                infinite
                responsive={[
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                    },
                  },
                ]}
              >
                {images.map((image, index) => (
                  <div className="responsive-carousel"
                    key={index}
                    style={{
                      width: "calc(50% - 10px)",
                      marginRight: "20px", 
                    }}
                  >
                    <Image
                      src={image}
                      alt={`Image ${index + 1}`}
                      style={{ width: "100%", height: "auto", borderRadius: 8 }}
                    />
                  </div>
                ))}
              </Carousel>

            </div>

        </Flex>    


      </Content>
  );
};

export default Home;