import React, { useEffect, useState } from "react";
import { Modal, Checkbox, Button } from "antd";

const CookieSettingsModal = () => {
  const [visible, setVisible] = useState(false);
  const [accepted, setAccepted] = useState(false);

  const [analytics, setAnalytics] = useState(false);
  const [marketing, setMarketing] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookie_consent");
    if (!consent) {
      setVisible(true);
    }
  }, []);

  const handleAccept = () => {
    const consent = {
      necessary: true,
      analytics,
      marketing,
    };
    localStorage.setItem("cookie_consent", JSON.stringify(consent));
    setAccepted(true);
    setVisible(false);
  };

  const handleReject = () => {
    const consent = {
      necessary: true,
      analytics: false,
      marketing: false,
    };
    localStorage.setItem("cookie_consent", JSON.stringify(consent));
    setAccepted(false);
    setVisible(false);
  };

  return (
    <Modal
      title="🍪 Cookie Preferences"
      open={visible}
      closable={false}
      footer={[
        <Button key="reject" onClick={handleReject}>
          Reject All
        </Button>,
        <Button key="accept" type="primary" onClick={handleAccept}>
          Accept Selected
        </Button>,
      ]}
    >
      <p>
        We use cookies to enhance your experience. You can choose which types of cookies you allow.
        Necessary cookies are always enabled.
      </p>
      <Checkbox checked disabled>
        Strictly Necessary Cookies (Always Active)
      </Checkbox>
      <br />
      <Checkbox
        checked={analytics}
        onChange={(e) => setAnalytics(e.target.checked)}
      >
        Analytics Cookies
      </Checkbox>
      <br />
    </Modal>
  );
};

export default CookieSettingsModal;
